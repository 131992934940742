import React from "react";
import PropTypes from "prop-types";

const Header = props => (
  <header id="header" style={props.timeout ? { display: "none" } : {}}>
    <div className="logo">
      <span className="icon fa-user" />
    </div>
    <div className="content">
      <div className="inner">
        <h1>Roy Holden</h1>
        <p>A portfolio for my artwork.</p>
      </div>
      <ul className="icons">
        <li>
          <a
            href="https://www.facebook.com/roysholden"
            target="_blank"
            rel="noopener noreferrer"
            className="icon fa-facebook">
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/roysholden/"
            target="_blank"
            rel="noopener noreferrer"
            className="icon fa-instagram">
            <span className="label">Instagram</span>
          </a>
        </li>
      </ul>
    </div>
    <nav>
      <ul>
        <li>
          <a
            href="#"
            onClick={() => {
              props.onOpenArticle("about");
            }}>
            About
          </a>
        </li>
        <li>
          <a
            href="#"
            onClick={() => {
              props.onOpenArticle("gallery");
            }}>
            Gallery
          </a>
        </li>
        <li>
          <a
            href="#"
            onClick={() => {
              props.onOpenArticle("contact");
            }}>
            Contact
          </a>
        </li>
      </ul>
    </nav>
  </header>
);

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool
};

export default Header;
