import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./Gallery.scss";

function importAll(r, rThumb) {
  let images = [];
  let thumbnails = [];
  rThumb.keys().forEach((item, index) => thumbnails.push(rThumb(item)));
  r.keys().forEach((item, index) => {
    images.push({ original: r(item), thumbnail: thumbnails[index] });
  });
  return images;
}

const images = importAll(
  require.context("../../images/gallery", false, /\.(png|jpe?g)$/),
  require.context("../../images/gallery/thumbnails", false, /\.(png|jpe?g)$/)
);

const Gallery = props => {
  return (
    <article
      id="gallery"
      className={`${props.article === "gallery" ? "active" : ""} ${
        props.articleTimeout ? "timeout" : ""
      }`}
      style={{ display: "none" }}>
      <h2 className="major">Gallery</h2>
      <ImageGallery
        items={images}
        showFullscreenButton={false}
        showPlayButton={false}
        lazyLoad={true}
      />
      {props.close}
    </article>
  );
};

export default Gallery;
