import React from "react";
import { hidden } from "ansi-colors";

const Contact = props => {
  return (
    <article
      id="contact"
      className={`${props.article === "contact" ? "active" : ""} ${
        props.articleTimeout ? "timeout" : ""
      }`}
      style={{ display: "none" }}>
      <h2 className="major">Contact</h2>
      <form
        name="Contact Form"
        method="POST"
        action="/success"
        data-netlify="true">
        <input type="hidden" name="form-name" value="Contact Form" />
        <div className="field half first">
          <label htmlFor="name">Name</label>
          <input type="text" name="name" id="name" />
        </div>
        <div className="field half">
          <label htmlFor="email">Email</label>
          <input type="text" name="email" id="email" />
        </div>
        <div className="field">
          <label htmlFor="message">Message</label>
          <textarea name="message" id="message" rows="4" />
        </div>
        <ul className="actions">
          <li>
            <button type="submit" className="special">
              Send Message
            </button>
          </li>
          <li>
            <input type="reset" value="Reset" />
          </li>
        </ul>
      </form>
      {props.close}
    </article>
  );
};

export default Contact;
