import React from "react";
import pic03 from "../../images/roy.jpg";

const About = props => {
  return (
    <article
      id="about"
      className={`${props.article === "about" ? "active" : ""} ${
        props.articleTimeout ? "timeout" : ""
      }`}
      style={{ display: "none" }}>
      <h2 className="major">About</h2>
      <span className="image main profile">
        <img src={pic03} alt="" />
      </span>
      <p>
        My name is Roy Holden, I am from Hertfordshire in England. I did a lot
        of pencil sketching as a hobby when I was very young, that always
        motivated me towards art. After a break from art that seemed like a
        lifetime, I decided to try to get back into art as a new hobby around
        2011 by attempting to paint portraits, first family, then others.
      </p>
      <p>
        When I started painting around 2011 it was only as a winter hobby to get
        me through the cold, slow Canadian winters, then I found myself looking
        forward to the first snowfall because that’s when I typically put all my
        other hobbies aside and start painting.{" "}
      </p>
      <p>
        I have studied books that I have bought or been given as gifts, and
        learned as much as possible from magazine subscriptions and by watching
        artists paint on “YouTube”. Painting has been is a great hobby for me.
        Recently I have put more focus into my artwork and have decided to
        pursue portrait painting as a career. I work in acrylics and oils, but
        mostly oil often using acrylic as an underpainting.
      </p>
      <p>
        Primarily I only paint portraits, but I have done one landscape based on
        “The River of Light” by Frederic Edwin Church. I love the old Masters
        work from the Renaissance and the Baroque periods. Realism, high
        contrast and rich colours spark my interest and enthusiasm.
      </p>
      <p>
        If you are interested in having a portrait commissioned please contact
        me and we can discuss the subject(s), resource material, size, style and
        cost. A typical range would be $500 - $1,500
      </p>
      {props.close}
    </article>
  );
};

export default About;
