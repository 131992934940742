import React from "react";
import PropTypes from "prop-types";

const Footer = props => (
  <footer id="footer" style={props.timeout ? { display: "none" } : {}}>
    <p className="copyright">
      &copy; 2019 Roy Holden (Powered by{" "}
      <a
        href="https://www.netlify.com/"
        target="_blank"
        rel="noopener noreferrer">
        Netlify
      </a>
      ){" "}
    </p>
  </footer>
);

Footer.propTypes = {
  timeout: PropTypes.bool
};

export default Footer;
