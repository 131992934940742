import PropTypes from "prop-types";
import React from "react";
import About from "./modals/About";
import Contact from "./modals/Contact";
import Gallery from "./modals/Gallery";

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle();
        }}
      />
    );

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: "flex" } : { display: "none" }}>
        <Gallery
          article={this.props.article}
          articleTimeout={this.props.articleTimeout}
          close={close}
        />

        <About
          article={this.props.article}
          articleTimeout={this.props.articleTimeout}
          close={close}
        />

        <Contact
          article={this.props.article}
          articleTimeout={this.props.articleTimeout}
          close={close}
        />
      </div>
    );
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired
};

export default Main;
